<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-full mr-auto">
    <span>
      <h4 class="mb-5">Supplier Invoice OCR</h4>
    </span>
    <vs-divider style="width: 100%; margin-left: 0%">Supplier Data</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <label class="vs-input--label">Supplier Code</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="Code"
          :custom-label="codeWithName"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span v-if="!dt.option.Code" class="option__title">
                Type to search
              </span>
              <span v-else class="option__title">
                {{ dt.option.Code }} - {{ dt.option.Name }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.Code }} - {{ dt.option.Name }}
              </span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="supplier == null && status_submit"
          >This field is required</span
        >
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          v-if="supplier.ID == 0"
          class="w-full"
          label="Supplier Name"
          name="Supplier Name"
          v-model="supplier_name"
          placeholder="Supplier Name"
        />
        <vs-input
          v-else
          class="w-full input-readonly"
          label="Supplier Name"
          name="Supplier Name"
          v-model="supplier_name"
          placeholder="Supplier Name"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="Supplier PIC"
          name="Supplier PIC"
          v-model="supplier_contact_name"
          placeholder="Supplier PIC"
        />
      </div>
      <div class="vx-col w-1/2">
        <vs-input
          class="w-full"
          label="PIC Phone Number"
          name="PIC Phone Number"
          v-model="supplier_phone"
          placeholder="PIC Phone Number"
          @keypress="isNumber($event)"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Warehouse</label>
        <multiselect
          class="selectExample"
          v-model="warehouse"
          :options="optionWarehouse"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="warehouse == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div v-if="this.supplier.Code != 'ONETIME'" class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank Name</label>
        <multiselect
          class="selectExample"
          v-model="table.bank"
          v-validate="'required'"
          :options="optionSupplierAccountName"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="account_number"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} - {{ dt.option.account_number }}
                {{ dt.option.account_name }} - {{ dt.option.bank_branch }}
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.bank_name }} - {{ dt.option.account_number }}
                {{ dt.option.account_name }} - {{ dt.option.bank_branch }}
              </span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="table.bank.bank_name == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div v-else class="vx-row mb-2">
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full"
          label="Bank Name"
          v-validate="'required'"
          name="Bank Name"
          v-model="table.bank.bank_name"
          placeholder="Bank Name"
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full"
          label="Account Number"
          v-validate="'required'"
          name="Account Number"
          v-model="table.bank.account_number"
          placeholder="Account Number"
        />
      </div>
      <div class="vx-col w-1/3">
        <vs-input
          class="w-full"
          v-validate="'required'"
          label="Account Name"
          name="Account Name"
          v-model="table.bank.account_name"
          placeholder="Account Name"
        />
      </div>
    </div>
    <span
      class="text-danger text-sm"
      v-if="(table.bank.bank_name == null || table.bank.account_number == null || table.bank.account_name == null) && status_submit"
      >The field Bank is required</span
    >
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full"
          label="Supplier Tax Number"
          name="Supplier Tax Number"
          v-model="tr_number"
          @keypress="isNumberAndFormat($event)"
          placeholder="Supplier Tax Number"
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Tax Date</label>
        <datepicker
          class="w-full"
          :inline="false"
          name="Invoice Date"
          v-model="tr_date"
          placeholder="Select Tax Date"
          format="MMMM dd, yyyy"
        ></datepicker>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">PO / GR Data</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">PO Code</label>
        <multiselect
          class="selectExample"
          v-model="po"
          :options="optionPO"
          :multiple="true"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="code"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.code }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="po == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="PO Date"
          name="PO Date"
          v-model="po_date"
          readonly
        />
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Proof of GR Code</label>
        <multiselect
          class="selectExample"
          v-model="gr"
          :options="optionGR"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="bast_code"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.bast_code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.bast_code }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="gr == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Proof of GR Date"
          name="Proof of GR Date"
          v-model="gr_dates"
          readonly
        />
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">Invoice Data</vs-divider>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="Barcode"
          name="Barcode"
          v-model="barcode"
          placeholder="Barcode"
        />
        <span
          class="text-danger text-sm"
          v-if="barcode == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          v-validate="'required'"
          class="w-full"
          label="External Code"
          name="External Code"
          v-model="table.inv_code"
          placeholder="External Code"
        />
        <span
          class="text-danger text-sm"
          v-if="table.inv_code == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Invoice Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="table.inv_date"
          placeholder="Select Invoice Date"
          format="MMMM dd, yyyy"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="table.inv_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <!-- <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Created Date"
          name="Created Date"
          v-model="createdDate"
          readonly
        />
      </div>
    </div> -->
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Term</label>
        <multiselect
          class="selectExample"
          v-model="table.payment_term"
          :options="optionPaymentTerm"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="id"
          label="name"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.name }}</span>
            </div>
          </template>
        </multiselect>
        <span
          class="text-danger text-sm"
          v-if="table.payment_term == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <!-- <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Due Date"
          name="Due Date"
          v-model="due_date"
          readonly
        />
      </div> -->
      <div class="vx-col w-full">
        <label class="vs-input--label">Invoice Due Date</label>
        <datepicker
          class="w-full"
          v-validate="'required'"
          :inline="false"
          name="Invoice Date"
          v-model="due_date"
          placeholder="Due date"
          format="MMMM dd, yyyy"
        ></datepicker>
        <span
          class="text-danger text-sm"
          v-if="due_date == null && status_submit"
          >This field is required</span
        >
      </div>
    </div>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <vs-input
          class="w-full input-readonly"
          label="Currency Code"
          name="Currency Code"
          v-model="currency_code"
          readonly
        />
      </div>
    </div>
    <!-- <div class="vx-row mb-2 ml-1 mt-6">
      <h5>Line Item</h5>
    </div> -->
    <vs-divider style="width: 100%; margin-left: 0%"><h5>Line Item GR</h5></vs-divider>
    <table style="z-index: 99 !important" class="vs-table vs-table--tbody-table">
      <tr>
        <th>Item</th>
        <th width="12%">Qty</th>
        <th>Total Price</th>
        <th>Discount</th>
        <th>Net</th>
        <th>Tax</th>
        <th width="15%">WH Tax</th>
        <th>WH Tax Value</th>
      </tr>
      <template v-for="(tr, indextr) in table.data">
        <tr class="tr-values vs-table--tr tr-table-state-null selected"
          v-bind:key="indextr">
            <td>
              <div class="vx-row ml-0">
                <span class="w-5/5">{{ tr.item_name }}</span>
              </div>
              <div class="ml-0">
                <span v-if="tr.project" class="w-5/5">Progress PO :
                  {{
                    tr.progress_po
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }} %<br>
                </span>
                <span v-if="tr.project" class="w-5/5">Progress GR :
                  {{
                    tr.progress_bast
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }} %
                </span>
                <span v-else class="w-5/5"
                  >Received :
                  {{
                    tr.qty_received
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}
                  {{ tr.item_unit }}
                </span>
              </div>
            </td>
            <td>
              <vx-input-group>
                <vs-input
                  v-validate="'required'"
                  @keypress="isNumber($event)"
                  @input="formatNumber(indextr, 'qty')"
                  class="w-full"
                  name="Qty Inv"
                  @focus="$event.target.select()"
                  v-model="tr.quantity"
                  :disabled="tr.project"
                />
                <span
                  class="text-danger text-sm"
                  v-if="tr.quantity == null && status_submit"
                  >This field is required</span
                >
  
                <template slot="append">
                  <div class="append-text bg-dark">
                    <span style="color: black; outline-color: black">{{ tr.item_unit }}</span>
                  </div>
                </template>
              </vx-input-group>
            </td>
            <td>
              <div class="vx-row ml-0">
                <vs-input
                  v-validate="'required'"
                  @keypress="isNumber($event)"
                  @input="formatNumber(indextr, 'price')"
                  @focus="$event.target.select()"
                  class="w-full"
                  name="Price Inv"
                  v-model="tr.net"
                />
                <span
                  class="text-danger text-sm"
                  v-if="tr.net == null && status_submit"
                  >This field is required</span
                >
              </div>
            </td>
            <td>
              <div class="vx-row ml-0">
                <vs-input
                  v-validate="'required'"
                  @keypress="isNumber($event)"
                  @input="formatNumber(indextr, 'discount')"
                  @focus="$event.target.select()"
                  class="w-full"
                  name="Discount"
                  v-model="tr.discount"
                />
                <span
                  class="text-danger text-sm"
                  v-if="tr.discount == null && status_submit"
                  >This field is required</span
                >
              </div>
            </td>
            <td>
              <div class="vx-row ml-0">
                <vs-input
                  class="w-full input-readonly"
                  name="Tax"
                  v-model="tr.subtotal"
                  readonly
                />
              </div>
            </td>
            <td>
              <div class="vx-row ml-0">
                <vs-input
                  v-validate="'required'"
                  class="w-full input-readonly"
                  name="Tax"
                  v-model="tr.tax"
                  readonly
                />
                <span
                  class="text-danger text-sm"
                  v-if="tr.tax == null && status_submit"
                  >This field is required</span
                >
              </div>
            </td>
            <td>
              <div class="vx-row ml-0">
                  <multiselect
                    class="selectExample "
                    v-model="tr.wh_tax_object"
                    :options="optionWhTax"
                    :multiple="false"
                    :allow-empty="true"
                    :group-select="false"
                    placeholder=" Type to search"
                    track-by="Name"
                    label="Name"
                  >
                    <template slot="singleLabel" slot-scope="dt">
                      <span class="option__desc">
                        <span v-if="!dt.option.Name" class="option__title">
                          Type to search
                        </span>
                        <span v-else class="option__title">
                          {{ dt.option.Name }}
                        </span>
                      </span>
                    </template>
  
                    <template slot="option" slot-scope="dt">
                      <div class="option__desc">
                        <span class="option__title">
                          {{ dt.option.Name }}
                        </span>
                      </div>
                    </template>
                  </multiselect>
              </div>
            </td>
            <td>
              <div class="vx-row ml-0">
                <vs-input
                  v-validate="'required'"
                  class="w-full input-readonly"
                  name="Wh Tax"
                  v-model="tr.wh_tax"
                  readonly
                />
              </div>
            </td>
        </tr>
      </template>
    </table>

    <vs-divider style="width: 100%; margin-left: 0%"><h5>Line Item OCR</h5></vs-divider>
    <table style="z-index: 99 !important" class="vs-table vs-table--tbody-table">
      <tr>
        <th>Item</th>
        <th width="12%">Qty</th>
        <th>Total Price</th>
        <th>Discount</th>
        <th>Net</th>
        <th>Tax</th>
      </tr>
      <template v-for="(tr,index) in table.data_ocr">
        <tr class="tr-values vs-table--tr tr-table-state-null selected"
          v-bind:key="index">
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.item_name_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.quantity_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.price_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.discount_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.net_ocr }}</span>
            </div>
          </td>
          <td>
            <div class="vx-row ml-0">
              <span class="w-full">{{ tr.tax_ocr }}</span>
            </div>
          </td>
        </tr>
      </template>
    </table>

    <!-- <div class="vx-row mb-2 mt-5 ml-1">
      <h5>Charges</h5>
    </div> -->
    <!-- <vs-table stripe border description :sst="true" :data="table.data_charges">
      <template slot="thead">
        <vs-th>Charge Name</vs-th>
        <vs-th>Value</vs-th>
        <vs-th>Tax</vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
          <vs-td>
            <div class="vx-row ml-0">
              <span class="w-5/5">{{
                data[indextr].charge_name.replace(/-/g, " ")
              }}</span>
            </div>
          </vs-td>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                v-validate="'required'"
                @keypress="isNumber($event)"
                @input="formatNumber(indextr, 'value')"
                @focus="$event.target.select()"
                class="w-full"
                name="Value"
                v-model="data[indextr].value"
              />
              <span
                class="text-danger text-sm"
                v-if="data[indextr].value == null && status_submit"
                >This field is required</span
              >
            </div>
          </vs-td>
          <div style="display: none"> -->
            <!-- <p>{{ (tax_charges[indextr] = data[indextr].tax) }}</p> -->
            <!-- <p>{{ (charges[indextr] = data[indextr].value) }}</p> -->
          <!-- </div>
          <vs-td>
            <div class="vx-row ml-0">
              <vs-input
                class="w-full input-readonly"
                name="Tax"
                v-model="data[indextr].tax"
                readonly
              />
            </div>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table> -->
    <vs-divider style="width: 100%; margin-left: 0%"><h5>Summary</h5></vs-divider>
    <div class="vx-row w-5/5 mb-3 mt-6">
      <vs-input
        class="w-1/5 input-readonly"
        label="Item Value"
        name="Item Value"
        v-model="table.item_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pl-1 pr-1"
        label="Charges Value"
        name="Charges Value"
        v-model="table.charges_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Tax Value"
        name="Tax Value"
        v-model="table.tax_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly pr-1"
        label="Discount Value"
        name="Discount Value"
        v-model="table.discount_value"
        readonly
      />

      <vs-input
        class="w-1/5 input-readonly"
        label="Total Invoice Value"
        name="Total Invoice Value"
        v-model="table.total_inv_value"
        readonly
      />
    </div>
    <vs-divider style="width: 100%; margin-left: 0%">Notes</vs-divider>
    <vs-textarea
      v-model="new_notes"
    />
    <vs-divider style="width: 100%; margin-left: 0%">Attachment</vs-divider>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col w-full">
        <label class="vs-input--label">Type</label>
        <multiselect
          class="selectExample"
          v-model="attachment"
          :options="optionAttachmentType"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="DocType"
          :disabled="false"
        >
        </multiselect>
      </div>
    </div>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span>File</span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <input
            id="fileInput"
            name="file"
            class="w-full inputx"
            type="file"
            ref="file"
            accept=".jpg, .jpeg, .png, .pdf"
            multiple
          />
        </div>
      </div>
    </div>

    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <div class="vx-col sm:w-1/3 w-full">
        <span></span>
      </div>
      <div class="vx-col sm:w-2/3 w-full">
        <div class="vx-col sm:w-4/5 w-full">
          <vs-button class="mr-3 mb-2" @click="handleAttachment"
            >Add Attachment</vs-button
          >
        </div>
      </div>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%"
      >List Attachment</vs-divider
    >
    <div class="vx-row mb-3 mt-6 w-4/4" style="width: 100%; margin-left: 0%">
      <table class="vs-table vs-table--tbody-table">
        <template v-for="(tr, i) in table.file">
          <tr
            class="tr-values vs-table--tr tr-table-state-null selected"
            v-bind:key="tr.name"
          >
            <td class="td vs-table--td">{{ tr.doc_type }}</td>
            <td class="td vs-table--td">{{ tr.name }}</td>
            <td class="td vs-table--td">
              <vx-tooltip text="Show" v-if="tr.path != ''">
                <vs-button
                  type="line"
                  icon-pack="feather"
                  @click.stop="downloadFileAwsS3(tr.path)"
                  icon="icon-eye"
                />
              </vx-tooltip>
            </td>
            <td class="td vs-table--td">
              <template>
                <vx-tooltip text="Delete">
                  <vs-button
                    type="line"
                    icon-pack="feather"
                    @click.stop="handleDeleteAttachment(i, tr.id)"
                    icon="icon-trash"
                  />
                </vx-tooltip>
              </template>
            </td>
          </tr>
        </template>
      </table>
    </div>
    <vs-divider style="width: 100%; margin-left: 0%"></vs-divider>

    <div class="vx-row mb-6 mt-6">
      <div class="vx-col w-full" style="margin-bottom: 150px">
        <vs-button class="mr-3 mb-2" @click="handleCreate">Create</vs-button>
        <!-- <vs-button v-else class="mr-3 mb-2" @click="handleUpdate"
          >Update</vs-button
        > -->
      </div>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  props: {
    piId: {
      type: Number,
    },
    vendor: {
      type: String,
    },
    poCode: {
      type: String,
    },
    invCode: {
      type: String,
    },
    warehouseCode: {
      type: String,
    },
    warehouseName: {
      type: String,
    },
    createdDate: {
      type: String,
    },
    dueDate: {
      type: String,
    },
    project: {
      type: Boolean,
    },
  },
  created() {},
  data() {
    return this.initialState();
  },
  methods: {
    codeWithName ({ Name, Code }) {
      if (Name != undefined) {
        return `${Code} - ${Name}`
      }
      return `${Code}`
    },
    initialState() {
      return {
        attachment: null,
        attachment_type: "",
        search: "",
        offset: 0,
        limit: 10,
        status_submit: false,
        statusItemDouble: false,
        table: {
          inv_code: null,
          inv_date: null,
          payment_term: null,
          bank: {},
          bank_name: null,
          account_name: null,
          account_number: null,
          total_inv_value: 0,
          item_value: 0,
          discount_value: 0,
          charges_value: 0,
          tax_value: 0,
          data: [],
          data_ocr: [],
          data_charges: [],
          file: [],
        },
        // tax_item: [],
        // tax_charges: [],
        po_type: "",
        barcode: "",
        gr_dates: "",
        supplier_name: "",
        new_notes: "",
        gr_codes: "",
        charges: [],
        net: [],
        total: [],
        taxx: [0, 0],
        file: {},
        total_discount: 0,
        type_submit: "",
        tr_number: null,
        tr_date: null,
        due_date: null,
        invoice_baseline: null,
        received_date: null,
        dn_date: null,
        supplier: [{"ID": 99999}],
        supplier_contact_name: null,
        supplier_phone: null,
        supplier_address: null,
        warehouse: null,
        po: null,
        // po: null,
        po_code: null,
        po_id: null,
        po_date: null,
        gr: null,
        optionAttachmentType: [],
        optionSupplier: [],
        optionWarehouse: [],
        optionPaymentTerm: [],
        optionSupplierAccountName: [],
        optionPO: [],
        optionPO2: [],
        optionGR: [],
        grOrderNumber: "",
        bast_ids: "",
        currency_code: "",
        po_code_external: "",
        attachment_ids: [],
        oldValue: [],
        newValue: []
      };
    },
    isNumberAndFormat: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;

      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      }

      if (charCode != 8 && this.tr_number != null) {
        if (this.tr_number.length == 3) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length == 7) {
          this.tr_number = this.tr_number + "-";
        } else if (this.tr_number.length == 10) {
          this.tr_number = this.tr_number + ".";
        } else if (this.tr_number.length > 18) {
          evt.preventDefault();
        }
      }
    },
    getOptionAccountBank(supplier_id, bankIDSelected = 0) {
      let warehouse_id = this.warehouse == null ? 0 : this.warehouse.ID
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/supplier-account/" + supplier_id, {
          params: {
            warehouse_id: warehouse_id,
            territory_id: this.$userLogin.territory_id,
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          // let option = [];
          resp.data.account.map((v) => {
            const newOption = {
              bank_name: v.BankName,
              bank_id: v.BankID,
              account_name: v.AccountName,
              account_number: v.AccountNumber,
              bank_branch: "Branch " + v.BankBranch,
            };
            this.optionSupplierAccountName.push(newOption);
            if (bankIDSelected != 0 && bankIDSelected != "") {
              if (v.BankID == bankIDSelected) {
                // this.table.bank = {
                //   bank_name : v.BankName,
                //   bank_id: v.BankID,
                //   account_name: v.AccountName,
                //   account_number: v.AccountNumber
                // }
              }
            } else {
              if (v.IsDefault == 1) {
                this.table.bank = {
                  bank_name: v.BankName,
                  bank_id: v.BankID,
                  account_name: v.AccountName,
                  account_number: v.AccountNumber,
                  bank_branch: "Branch " + v.BankBranch,
                };
              }
            }
          });
          this.$vs.loading.close();
        });
    },
    getOptionPaymentTerm() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/payment-term", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPaymentTerm = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Payment Term option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    handleDeleteAttachment(index, id) {
      this.table.file = this.table.file.filter((item, i) => i != index);
      this.deleteFile(id);
    },
    handleAttachment() {
      if (this.$refs.file.files[0]) {
        let a = this.$refs.file.files
        for (let i = 0; i < a.length; i++) {
          if(a[i].size <= 5000000){ // 5mb
            this.file = a[i];
            this.uploadData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: a[i].name + " is greater than 5MB",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }
        document.querySelector("#fileInput").value = "";
      } else {
        this.$vs.notify({
          title: "Error",
          text: "No file chosen.",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }
    },
    handleShowAttachment(selected) {
      if (selected.path != "") {
        window.open(selected.path);
      }
    },
    // handleUpdate() {
    //   this.status_submit = true;
    //   this.$validator.validateAll().then((result) => {
    //     if (result) {
    //       this.type_submit = "update";
    //       this.$vs.loading();
    //       this.postData();
    //     }
    //   });
    // },
    handleCreate() {
      this.status_submit = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (this.statusItemDouble == false) {
            this.type_submit = "create";
            this.$vs.loading();
            this.postData();
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Please check line item.",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        }
      });
    },
    formatNumber(index, status) {
      if (status === "qty") {
        if (this.table.data[index].quantity.replace(/,/g, "").length < 16) {
          var str = this.table.data[index].quantity.toString().split(".");
          if (str.length > 2) {
            this.table.data[index].quantity = this.table.data[index].quantity
              .toString()
              .replace(/\.+$/, "");
          }

          if (str.length == 2) {
            if (str[1].length > 3) {
              this.table.data[index].quantity = this.table.data[index].quantity
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].quantity = this.table.data[index].quantity
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          this.table.data[index].quantity = this.table.data[index].quantity
            .toString()
            .slice(0, -1);
        }
      } else if (status === "price") {
        if (this.table.data[index].net.replace(/,/g, "").length < 16) {
          var str1 = this.table.data[index].net.toString().split(".");
          if (str1.length > 2) {
            this.table.data[index].net = this.table.data[index].net
              .toString()
              .replace(/\.+$/, "");
          }

          if (str1.length == 2) {
            if (str1[1].length > 3) {
              this.table.data[index].net = this.table.data[index].net
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].net = this.table.data[index].net
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.net[index] = this.table.data[index].net;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      } else if (status === "value") {
        if (
          this.table.data_charges[index].value.replace(/,/g, "").length < 16
        ) {
          var str2 = this.table.data_charges[index].value.toString().split(".");
          if (str2.length > 2) {
            this.table.data_charges[index].value = this.table.data_charges[
              index
            ].value
              .toString()
              .replace(/\.+$/, "");
          }

          if (str2.length == 2) {
            if (str2[1].length > 3) {
              this.table.data_charges[index].value = this.table.data_charges[
                index
              ].value
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.charges[index] = this.table.data_charges[index].value;

          this.cal();
        } else {
          this.table.data_charges[index].value = this.table.data_charges[
            index
          ].value
            .toString()
            .slice(0, -1);
        }
      } else if (status === "tax") {
        // if (this.table.data_charges[index].tax.replace(/,/g, "").length < 16) {
        //   var str3 = this.table.data_charges[index].tax.toString().split(".");
        //   if (str3.length > 2) {
        //     this.table.data_charges[index].tax = this.table.data_charges[
        //       index
        //     ].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }
        //   if (str3.length == 2) {
        //     if (str3[1].length > 3) {
        //       this.table.data_charges[index].tax = this.table.data_charges[
        //         index
        //       ].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }
        //   this.table.data_charges[index].tax = Number(
        //     this.table.data_charges[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_charges[index] = this.table.data_charges[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data_charges[index].tax = this.table.data_charges[
        //     index
        //   ].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "tax-item") {
        // if (this.table.data[index].tax.replace(/,/g, "").length < 16) {
        //   var str4 = this.table.data[index].tax.toString().split(".");
        //   if (str4.length > 2) {
        //     this.table.data[index].tax = this.table.data[index].tax
        //       .toString()
        //       .replace(/\.+$/, "");
        //   }
        //   if (str4.length == 2) {
        //     if (str4[1].length > 3) {
        //       this.table.data[index].tax = this.table.data[index].tax
        //         .toString()
        //         .slice(0, -1);
        //     }
        //   }
        //   this.table.data[index].tax = Number(
        //     this.table.data[index].tax.replace(/,/g, "")
        //   )
        //     .toString()
        //     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        //   this.tax_item[index] = this.table.data[index].tax;
        //   this.cal();
        // } else {
        //   this.table.data[index].tax = this.table.data[index].tax
        //     .toString()
        //     .slice(0, -1);
        // }
      } else if (status === "discount") {
        if (this.table.data[index].discount.replace(/,/g, "").length < 16) {
          var str5 = this.table.data[index].discount.toString().split(".");
          if (str5.length > 2) {
            this.table.data[index].discount = this.table.data[index].discount
              .toString()
              .replace(/\.+$/, "");
          }

          if (str5.length == 2) {
            if (str5[1].length > 3) {
              this.table.data[index].discount = this.table.data[index].discount
                .toString()
                .slice(0, -1);
            }
          }

          this.table.data[index].discount = this.table.data[index].discount
            .replace(/,/g, "")
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          // this.discount[index] = this.table.data[index].discount;
          this.cal();
        } else {
          this.table.data[index].net = this.table.data[index].net
            .toString()
            .slice(0, -1);
        }
      }
    },
    cal() {
      let a = 0;
      let b = 0;
      let c = 0;
      let d = 0;
      let e = 0;
      let f = 0; // wh tax

      // calculate tax item
      this.table.data.map((r, i) => {
        this.table.data[i].tax =
          parseFloat(parseInt(this.table.data[i].tax_rate) / 100) *
          parseFloat(
            this.table.data[i].net.replace(/,/g, "") -
              parseFloat(
                this.table.data[i].discount.toString().replace(/,/g, "")
              )
          );
        this.table.data[i].wh_tax =
          parseFloat(parseInt(this.table.data[i].wh_tax_rate) / 100) *
          parseFloat(
            this.table.data[i].net.replace(/,/g, "") -
              parseFloat(
                this.table.data[i].discount.toString().replace(/,/g, "")
              )
          );
        this.table.data[i].subtotal = parseFloat(
          this.table.data[i].net.replace(/,/g, "") -
            parseFloat(this.table.data[i].discount.toString().replace(/,/g, ""))
        )
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        a += this.table.data[i].tax;
        f += this.table.data[i].wh_tax;
        e += parseFloat(
          this.table.data[i].discount.toString().replace(/,/g, "")
        );
        d += parseFloat(this.table.data[i].net.toString().replace(/,/g, ""));
        this.table.data[i].tax = this.table.data[i].tax
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.data[i].wh_tax = this.table.data[i].wh_tax
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          this.oldValue[i] = this.newValue[i]
      });

      // calculate tax charge
      this.table.data_charges.map((r, i) => {
        this.table.data_charges[i].tax =
          (this.table.data_charges[i].tax_rate / 100) *
          parseInt(this.table.data_charges[i].value.replace(/,/g, ""));
        b += this.table.data_charges[i].tax;
        c += parseFloat(
          this.table.data_charges[i].value.toString().replace(/,/g, "")
        );
        this.table.data_charges[i].tax = this.table.data_charges[i].tax
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });

      // let a = 0;
      // this.tax_item.forEach((element) => {
      //   if (element !== "") {
      //     a += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      // let b = 0;
      // this.tax_charges.forEach((element) => {
      //   if (element !== "") {
      //     b += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });
      // this.charges.forEach((element) => {
      //   if (element !== "") {
      //     c += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      // this.net.forEach((element) => {
      //   if (element !== "") {
      //     d += parseInt(element.toString().replace(/,/g, ""));
      //   }
      // });

      this.$nextTick(() => {
        this.table.tax_value = parseInt(a + b + f) // tax invoice & tax charge & wh tax
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.tax_value_ori = (a + b + f)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.charges_value = c
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.item_value = d
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.discount_value = e
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.table.total_inv_value = (parseInt(a) + parseInt(b) + parseInt(f) + c + d - e)
          .toFixed(2)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    paramData() {
      return {
        pi_id: this.piId,
        supplier_address: this.supplier_address,
        supplier_name: this.supplier_name,
        supplier_contact_name: this.supplier_contact_name,
        supplier_phone: this.supplier_phone,
        supplier_id: this.supplier.ID,
        warehouse_name: this.warehouse.name,
        warehouse_id: this.warehouse.ID,
        warehouse_code: this.warehouse.code,
        inv_code: this.table.inv_code, //
        inv_date: this.table.inv_date, //
        due_invoice_period: this.table.payment_term.due_invoice_period, //
        reference_purchase_code: this.po_code,
        operating_unit_id: this.po[0].operating_unit_id,
        reference_purchase_id: this.po_id, // belum dipakai
        currency_code: this.po[0].currency_code,
        po_date: this.po_date,
        po_posting_date: this.po[0].posting_date, // belum dipakai
        territory_id: this.$userLogin.territory_id,
        territory_area_id: this.$userLogin.territory_area_id,
        payment_term_id: this.table.payment_term.id,
        payment_term_name: this.table.payment_term.name, //
        payment_term_name_po: this.payment_term_name_po, //
        bank_name: this.table.bank.bank_name, //
        bank_id: this.table.bank.bank_id, //
        account_name: this.table.bank.account_name, //
        account_number: this.table.bank.account_number, //
        total_inv_value: this.table.total_inv_value.toString(), //
        item_value: this.table.item_value.toString(), //
        charge_value: this.table.charges_value.toString(), //
        tax_value: this.table.tax_value_ori.toString(), //
        data_charge: this.table.data_charges, //
        total_discount: this.table.discount_value, //
        total_discount_po: this.po[0].discount, // belum dipakai
        total_po_value: this.po[0].total, // belum dipakai
        status_submit: this.type_submit, //
        tr_number: this.tr_number, //
        dn_date: this.dn_date,
        received_date: this.received_date,
        delivery_order_number: this.grOrderNumber,
        tr_date:
          this.tr_date == ""
            ? moment(String("0001-01-01T15:04:05Z07:00"))
            : this.tr_date, //
        due_date: moment(this.due_date).add(7, "h"),
        bast_ids: this.bast_ids,
        gr_codes: this.gr_codes,
        gr_dates: this.gr_dates,
        po_code_external: this.po_code_external,
        notes: this.new_notes,
        attachment_ids: this.attachment_ids,
        nt_type: this.table.project == true ? "1" : "0",
        data_line: this.table.data,
        barcode: this.barcode
      };
    },
    postData() {
      this.$http
        .post("/api/v1/pi/create-invoice-manual-ocr", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.handleClose();
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close();
        });
    },
    paramUpload() {
      var form = new FormData();
      form.append("pi_id", this.piId);
      form.append("attachment_type", this.attachment_type);
      form.append("file", this.file);
      form.append("type", this.file.type);
      return form;
    },
    uploadData() {
      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/upload-file", this.paramUpload(), {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.table.file.push({
              id: resp.data.attachment.ID,
              name: resp.data.attachment.NameFile,
              path: resp.data.attachment.PathFile,
              doc_type: resp.data.attachment.DocType,
            });
            this.attachment_ids.push(parseInt(resp.data.attachment.ID))
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close();
        });
    },
    deleteFile(id) {
      this.$vs.loading();
      var form = new FormData();
      form.append("id", id);
      this.$http
        .post("/api/v1/pi/delete-file", form, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          console.log(error)
          this.$vs.loading.close();
        });
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    getOptionSupplier() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            // let onetime = {}
            // onetime.ID = 0
            // onetime.PaymentTermID = 0
            // onetime.Code = "Onetime"
            // onetime.Name = "Onetime"
            // this.optionSupplier = [onetime].concat(resp.data.records);
            this.optionSupplier = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionWhTax() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/tax", {
          params: {
            length: 0,
            tax_group: "WHT"
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWhTax = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get WH Tax",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionWarehouse() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/warehouses", {
          params: {
            order: "name",
            sort: "asc",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionWarehouse = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Warehouse option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseOrder() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-po", {
          params: {
            supplier_id: this.supplier.ID,
            warehouse_id: this.warehouse.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPO = resp.data.records;
            console.log(resp.data.records);
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get PO option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.warehouse = null;
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseOrderNt() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-po-nt", {
          params: {
            supplier_id: this.supplier.ID,
            warehouse_id: this.warehouse.ID,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionPO = resp.data.records_code;
            this.optionPO2 = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get PO option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
            this.warehouse = null;
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseReceiveNt() {
      let optionGr = []
      let poDates = []
      this.po.map((d) => {
        let statusError = false
        this.optionPO2.map((e) => {
          if (statusError) {
            return
          }
          if(e.code == d.code && e.project){
            console.log('tes 1')
            this.$vs.notify({
                title: "Error",
                text: "Multiple PO only for Non project",
                color: "danger",
                position: "top-right",
                iconPack: "feather",
                icon: "icon-check",
              });
              this.po = []
              this.gr = []
              this.po_date = null
              this.gr_dates = null
              optionGr = []
              statusError = true
              return
          } else if (e.code == d.code && e.project == false) {
            console.log('tes 2')
            poDates.push(e.po_date)
            optionGr.push(e)
          }
        });
      })
      
      this.po_date = poDates.length > 0 ? poDates.join() : []
      this.optionGR = optionGr;
      // this.po_date = optionGr.length > 0 ? optionGr[0].po_date : null
    },
    getListItemNt() {
      let bast_id = [];
      let gr_code = [];
      let gr_date = [];
      let payment_term_name = [];
      let po_id = [];
      let po_code = [];
      // let gr_dn_date = [];
      // let gr_do_number = [];
      this.gr.map(function (obj) {
        bast_id.push(obj.id);
        gr_code.push(obj.bast_code);
        gr_date.push(obj.date);
        payment_term_name.push(obj.payment_term_name);
        po_id.push(obj.po_number_id);
        po_code.push(obj.code);
        // gr_dn_date.push(obj.dn_date);
        // gr_do_number.push(obj.delivery_order_number);
      });

      this.received_date = gr_date
        .map(function (e) {
          return e;
        })
        .sort()
        .reverse()[0];

      this.bast_ids = bast_id.join()
      this.gr_codes = gr_code.join()
      this.gr_dates = gr_date.join()
      this.payment_term_name_po = payment_term_name.join()
      this.po_id = po_id.join()
      this.po_code = po_code.join()

      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/list-bast-item", {
          bast_ids: bast_id.join(),
          // purchase_order_id: this.po.id.toString(),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.item_value = (0).toFixed(2);
            this.table.charges_value = (0).toFixed(2);
            this.table.tax_value = (0).toFixed(2);
            this.table.discount_value = (0).toFixed(2);
            this.table.total_inv_value = (0).toFixed(2);

            let dataCheck = []
            this.statusItemDouble = false
            // console.log("data bast line",resp.data.records)
            resp.data.records.map((obj) => {
              if (typeof dataCheck[obj.item_unit_id] === 'undefined') { // jika data tidak ada
                dataCheck[obj.item_unit_id] = obj.item_name + obj.item_unit + obj.price_po.toString()
              } else { // jika data item unit sudah ada
                if (dataCheck[obj.item_unit_id] != obj.item_name + obj.item_unit + obj.price_po.toString()) { // cek apakah price nya tidak sama
                  this.statusItemDouble = true
                  this.$vs.notify({
                    title: "Error",
                    text: obj.item_name + " have difference price.",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check",
                  });
                }
              }
              this.table.data.push({
                item_name: obj.item_name,
                project: obj.project,
                item_unit: obj.item_unit,
                qty_received: obj.qty_received.toString(),
                tax_rate: parseInt(obj.tax_rate),
                wh_tax_rate: 0,
                wh_tax_id: 0,
                wh_tax_object: null,
                amount_uom: parseInt(obj.amount_uom),
                subtotal: "0.00",
                quantity: obj.qty_received.toString(),
                discount: "0.00",
                tax: "0.00",
                wh_tax: "0.00",
                net: parseFloat(obj.progress_bast) == 0 ? (obj.qty_received*parseFloat(obj.price_po)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : ((parseFloat(obj.progress_bast)/100)*obj.qty_po*parseFloat(obj.price_po)).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                pr_id: obj.pr_id,
                discount_po: obj.discount_po.toString(),
                item_unit_id: obj.item_unit_id,
                price_po: obj.price_po.toString(),
                qty_po: obj.qty_po.toString(),
                progress_po: parseFloat(obj.progress_po),
                progress_bast: parseFloat(obj.progress_bast),
              });
              let netValue = parseFloat(obj.progress_bast) == 0 ? (obj.qty_received * parseFloat(obj.price_po)) : ((parseFloat(obj.progress_bast) / 100) * obj.qty_po * parseFloat(obj.price_po));
              netValue = Math.floor(netValue);
              console.log("net value",netValue)
              this.table.data_ocr = this.table.data_ocr.filter((ocr) => {
                if (netValue == ocr.price_ocr) {
                  return false; // Hilangkan elemen yang cocok
                }
                return true; // Pertahankan elemen yang tidak cocok
              });
              // this.table.data_ocr.forEach((ocr) => {
              //   console.log("data ocr", ocr);
              //   if (netValue == ocr.price_ocr) {
              //     ocr.matching = "yes";
              //   }
              // });
            });
            this.cal()
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get list item",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getOptionPurchaseReceive() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/list-gr", {
          params: {
            purchase_order_id: this.po.id,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionGR = resp.data.records;
            console.log(resp.data.records);
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get GR option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getListItem() {
      let gr_id = [];
      let gr_code = [];
      let gr_date = [];
      let gr_dn_date = [];
      let gr_do_number = [];
      this.gr.map(function (obj) {
        gr_id.push(obj.id);
        gr_code.push(obj.code);
        gr_date.push(obj.date);
        gr_dn_date.push(obj.dn_date);
        gr_do_number.push(obj.delivery_order_number);
      });

      console.log(this.gr)

      this.dn_date = gr_dn_date
        .map(function (e) {
          return e;
        })
        .sort()
        .reverse()[0];
      this.received_date = gr_date
        .map(function (e) {
          return e;
        })
        .sort()
        .reverse()[0];

      this.grOrderNumber = gr_do_number.join()
      this.gr_ids = gr_id.join()
      this.gr_codes = gr_code.join()

      this.$vs.loading();
      this.$http
        .post("/api/v1/pi/list-gr-item", {
          purchase_receive_ids: gr_id.join(),
          purchase_order_id: this.po.id.toString(),
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.table.item_value = (0).toFixed(2);
            this.table.charges_value = (0).toFixed(2);
            this.table.tax_value = (0).toFixed(2);
            this.table.discount_value = (0).toFixed(2);
            this.table.total_inv_value = (0).toFixed(2);

            resp.data.records.map((obj) => {
              this.table.data.push({
                item_name: obj.item_name,
                item_unit: obj.item_unit,
                qty_received: obj.qty_received,
                tax_rate: parseInt(obj.tax_rate),
                amount_uom: parseInt(obj.amount_uom),
                subtotal: "0.00",
                quantity: "0",
                discount: "0.00",
                tax: "0.00",
                net: "0.00",
                pr_id: obj.pr_id,
                discount_po: obj.discount_po,
                item_unit_id: obj.item_unit_id,
                price_po: obj.price_po,
                qty_po: obj.qty_po,
                // discount: parseFloat(obj.discount)
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // tax: parseFloat(obj.tax)
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // net: parseFloat(obj.net)
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // id: element.id,
              });
              // this.cal()
            });

            resp.data.data_charge.map((obj) => {
              this.table.data_charges.push({
                value_po: obj.value,
                tax_po: obj.tax,
                value: "0.00",
                tax: "0.00",
                // id: element.id,
                charge_name: obj.charge_name,
                tax_rate: parseInt(obj.tax_rate),
              });
              // _this.cal();
            });
          } else {
            console.log(resp);
            this.$vs.notify({
              title: "Error",
              text: "Failed to get list item",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getInvoiceBaseline() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/invoice-baseline/" + this.supplier.ID)
        .then((resp) => {
          if (resp.code == 200) {
            this.invoice_baseline = resp.data.invoice_baseline;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get invoice baseline",
              color: "warning",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getAttachmentType() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/attachment-type", {
          params: {
            group: "Supplier Invoice",
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionAttachmentType = resp.data.records;
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get attachment type",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/vendor-invoice-line-tempocr/" + this.piId)
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            console.log("data",resp)
            // this.warehouse_id = resp.data.warehouse_id;
            // this.supplier_id = resp.data.supplier_id;
            // this.supplier_code = resp.data.supplier_code;
            // this.supplier_contact_name = resp.data.supplier_contact_name;
            // this.supplier_phone = resp.data.supplier_phone;
            // this.invoice_baseline = resp.data.invoice_baseline;
            // this.gr_codes = resp.data.gr_codes;
            // this.dn_date = resp.data.dn_date;
            // this.po_date = resp.data.po_date;
            // this.po_date = moment(String(resp.data.po_date)).format("YYYY-MM-DD");
            // this.gr_dates = resp.data.gr_dates;
            // this.received_date = resp.data.received_date;
            
            this.barcode = resp.data.barcode;
            this.supplier_name = resp.data.supplier_name;
            this.po_type = resp.data.po_type;
            this.table.inv_date =
              this.invCode != 0 ? new Date(resp.data.inv_date) : null;
            if (resp.data.payment_term_id !== 0) {
              this.table.payment_term = {
                id: resp.data.payment_term_id,
                name: resp.data.payment_term_name,
                due_invoice_period: 0,
              };

              this.$nextTick(() => {
                this.due_date = this.dueDate;
              });
            }
            // this.getOptionAccountBank(resp.data.supplier_id,resp.data.bank_id)
            // // if (resp.data.bank_id != 0 || resp.data.bank_id != "") {
            //   this.table.bank = {
            //     bank_name : resp.data.bank_name,
            //     bank_id: resp.data.bank_id,
            //     account_name: resp.data.account_name,
            //     account_number: resp.data.account_number,
            //   }
            // }

            // this.tr_date = moment(String(resp.data.tr_date)).format("YYYY-MM-DD") == "0001-01-01" ? "" : new Date(resp.data.tr_date);
            // this.tr_number = resp.data.tr_number;
            this.notes = resp.data.supplier_invoice_notes.toString().split(";")
            // this.table.account_name = resp.data.account_name;
            this.currency_code = resp.data.currency_code;
            // this.table.account_number = resp.data.account_number;
            // this.table.discount_value = resp.data.total_discount
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.account_name = resp.data.account_name;
            // this.table.account_number = resp.data.account_number;
            // this.table.total_inv_value = resp.data.total_invoice_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.item_value = resp.data.item_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.charges_value = resp.data.charge_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.tax_value = resp.data.tax_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.tax_value_ori = resp.data.tax_value
            //   .toFixed(2)
            //   .toString()
            //   .replace(/,/g, "")
            //   .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // this.table.po_code = resp.data.po_code;
            // this.table.vendor = resp.data.vendor;
            // this.table.data = resp.data.data_line;
            // this.table.data_charges = resp.data.data_charge;

            let _this = this;
            resp.data.data_line_ocr.forEach(function (element, index) {
              _this.table.data_ocr.push({
                net_ocr: element.net_ocr
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                tax_ocr: element.tax_ocr
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // wh_tax_ocr: element.wh_tax_ocr
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                // wh_tax_value_ocr: element.wh_tax_value_ocr
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                quantity_ocr: element.quantity_ocr
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                discount_ocr: element.discount_ocr
                  .toFixed(2)
                  .toString()
                  .replace(/,/g, "")
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                price_ocr: element.price_ocr * element.quantity_ocr,
                // price_ocr: element.price_ocr
                //   .toFixed(2)
                //   .toString()
                //   .replace(/,/g, "")
                //   .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
                item_name_ocr: element.item_name_ocr,
                item_unit_ocr: element.item_unit_ocr,
                id_temp: element.id,
              });
              // _this.cal()
            });

            // resp.data.data_line.forEach(function (element, index) {
            //   const searchIndex = _this.optionWhTax.findIndex((wht) => wht.ID == element.wh_tax_id);
            //   _this.table.data.push({
            //     progress_po: element.progress_po,
            //     progress_bast: element.progress_bast,
            //     wh_tax_object: _this.optionWhTax[searchIndex],
            //     wh_tax_id: element.wh_tax_id,
            //     net: element.net_inv
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     tax: element.tax
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     wh_tax: element.wh_tax
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     quantity: element.quantity
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     item_name: element.item_name,
            //     item_unit: element.item_unit,
            //     id: element.id,
            //     amount_uom: element.amount_uom,
            //     qty_received: element.qty_received.toString(),
            //     tax_rate: element.tax_rate,
            //     wh_tax_rate: element.wh_tax_rate,
            //     discount: element.discount_inv
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     subtotal: "0.00",
            //     total_price_po: (parseFloat(element.price_po) * parseFloat(element.qty_po))
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     net_po: (parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount))
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     tax_po: ((parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount)) * (element.tax_rate/100))
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     wh_tax_po: ((parseFloat(element.price_po) * parseFloat(element.qty_po) - parseFloat(element.discount)) * (element.wh_tax_rate/100))
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     discount_po: element.discount
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     qty_po: element.qty_po
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //   });
            //   // _this.cal()
            // });

            // resp.data.data_charge.forEach(function (element, index) {
            //   _this.table.data_charges.push({
            //     value: element.value
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     tax: element.tax
            //       .toFixed(2)
            //       .toString()
            //       .replace(/,/g, "")
            //       .replace(/\B(?=(\d{3})+(?!\d))/g, ","),
            //     id: element.id,
            //     charge_name: element.charge_name,
            //     tax_rate: element.tax_rate,
            //   });
            //   // _this.cal()
            // });
            resp.data.data_attachment.map((r,i) => {
              _this.table.file.push({
                id: r.id,
                name: r.name,
                path: r.path,
                doc_type: r.doc_type,
              })
            })
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    }
  },
  mounted() {
    if (this.piId) {
      this.getData()
      this.table.inv_code = this.invCode != 0 ? this.invCode : null;
    }
    this.getOptionPaymentTerm();
    this.getOptionSupplier();
    this.getOptionWarehouse();
    this.getAttachmentType();
    this.getOptionWhTax();
  },
  computed: {},
  watch: {
    attachment: function () {
      this.attachment_type = this.attachment.DocType
    },
    optionPaymentTerm: function () {
      if (
        this.optionPaymentTerm.length > 0 &&
        this.table.payment_term != null
      ) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period =
          result[0].due_invoice_period;
      }
    },
    "table.payment_term": function () {
      // console.log("payment term", this.table.payment_term)
      if (this.optionPaymentTerm.length > 0 && this.table.payment_term != null) {
        let _this = this;
        var result = this.optionPaymentTerm.filter(function (o) {
          return o.id == _this.table.payment_term.id;
        });
        this.table.payment_term.due_invoice_period =
          result[0].due_invoice_period;

        if (this.supplier.Code.toLowerCase() == 'onetime') {
          if (this.table.payment_term != null && this.table.inv_date != null) {
            this.due_date = moment(String(this.table.inv_date))
              .add(this.table.payment_term.due_invoice_period, "days")
              .format("MM/DD/YYYY")
          }
        } else {
          if (this.invoice_baseline == "Invoice Date") {
            this.due_date = moment(String(this.table.inv_date))
              .add(result[0].due_invoice_period, "days")
              .format("MM/DD/YYYY")
          } else if (this.invoice_baseline == "Invoice Create Date") {
            // console.log(moment())
            // console.log(result[0].due_invoice_period)
            this.due_date = moment().add(result[0].due_invoice_period, "days").format("MM/DD/YYYY")
            // console.log(this.due_date)
          } else if (this.invoice_baseline == "Delivery Note Date") {
            this.due_date = moment(String(this.dn_date))
              .add(this.table.payment_term.due_invoice_period, "days")
              .format("MM/DD/YYYY")
          } else if (this.invoice_baseline == "GR Date") {
            this.due_date = moment(String(this.received_date))
              .add(this.table.payment_term.due_invoice_period, "days")
              .format("MM/DD/YYYY")
          }
        }
      }
    },
    // "table.inv_date": function () {
    //   if (this.supplier.Code.toLowerCase() == 'onetime') {
    //     if (this.table.payment_term != null && this.table.inv_date != null) {
    //       this.due_date = moment(String(this.table.inv_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     }
    //   } else {
    //     if (this.table.payment_term != null && this.invoice_baseline == "Invoice Date") {
    //       this.due_date = moment(String(this.table.inv_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     } else if (this.table.payment_term != null && this.invoice_baseline == "Invoice Create Date") {
    //       this.due_date = moment()
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     } else if (this.table.payment_term != null && this.invoice_baseline == "Delivery Note Date") {
    //       this.due_date = moment(String(this.dn_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     } else if (this.table.payment_term != null && this.invoice_baseline == "GR Date") {
    //       this.due_date = moment(String(this.received_date))
    //         .add(this.table.payment_term.due_invoice_period, "days")
    //         .format("MM/DD/YYYY")
    //     }
    //   }
    // },
    supplier: function () {
      this.po = null;
      this.optionPO = [];
      this.warehouse = null;
      this.table.bank.bank_id = 0;
      this.supplier_name = this.supplier.Name
      this.supplier_contact_name = this.supplier.ContactName
      this.supplier_phone = this.supplier.ContactMobile
      if (this.supplier.Code.toLowerCase() != 'onetime') {
        this.getOptionAccountBank(this.supplier.ID, 0);
        this.getInvoiceBaseline();
      }
      if (this.warehouse != null) {
        this.getOptionPurchaseOrderNt();
      } 
      // if (this.warehouse != null && this.po_type == "0") {
      //   this.getOptionPurchaseOrder();
      // } else if (this.warehouse != null && this.po_type == "1") {
      //   this.getOptionPurchaseOrderNt();
      // }
      this.optionPaymentTerm.map((v) => {
        if (v.id == this.supplier.PaymentTermID) {
          this.table.payment_term = {
            due_invoice_period: v.due_invoice_period,
            id: v.id,
            name: v.name,
            payment_method_id: v.payment_method_id,
            payment_method_name: v.payment_method_name,
          };
        }
      });
    },
    warehouse: function () {
      console.log("PO TYPE",this.po_type)
      if (this.supplier != null) {
        this.getOptionPurchaseOrderNt();
        this.getOptionAccountBank(this.supplier.ID, 0);
        this.po = null;
      }
      // if (this.supplier != null && this.po_type == "0") {
      //   this.getOptionPurchaseOrder();
      //   this.getOptionAccountBank(this.supplier.ID, 0);
      //   this.po = null;
      // } else if (this.supplier != null && this.po_type == "1") {
      //   this.getOptionPurchaseOrderNt();
      //   this.getOptionAccountBank(this.supplier.ID, 0);
      //   this.po = null;
      // }
    },
    po: function () {
        this.getOptionPurchaseReceiveNt();
        this.gr = null;
        if (this.po.length > 0) {
          console.log("TES",this.po[0].supplier_pic_name)
          this.currency_code = this.po[0].currency_code
          this.supplier_name = this.po[0].supplier_name
          if (this.po[0].supplier_pic_name != '' && this.po[0].supplier_pic_name != null) {
            this.supplier_contact_name = this.po[0].supplier_pic_name
          }
          
          if (this.po[0].supplier_pic_phone != '' && this.po[0].supplier_pic_phone != null) {
            this.supplier_phone = this.po[0].supplier_pic_phone
          }
  
          if (this.po[0].supplier_address != '' && this.po[0].supplier_address != null) {
            this.supplier_address = this.po[0].supplier_address
          }
        }
      // if(this.po_type == "1"){
      //   this.getOptionPurchaseReceiveNt();
      //   this.gr = null;
      //   if (this.po.length > 0) {
      //     console.log("TES",this.po[0].supplier_pic_name)
      //     this.currency_code = this.po[0].currency_code
      //     this.supplier_name = this.po[0].supplier_name
      //     if (this.po[0].supplier_pic_name != '' && this.po[0].supplier_pic_name != null) {
      //       this.supplier_contact_name = this.po[0].supplier_pic_name
      //     }
          
      //     if (this.po[0].supplier_pic_phone != '' && this.po[0].supplier_pic_phone != null) {
      //       this.supplier_phone = this.po[0].supplier_pic_phone
      //     }
  
      //     if (this.po[0].supplier_address != '' && this.po[0].supplier_address != null) {
      //       this.supplier_address = this.po[0].supplier_address
      //     }
      //   }
      // } else {
      //   this.getOptionPurchaseReceive();
      //   this.gr = null;
      //   this.currency_code = this.po.currency_code
      //   this.po_code_external = this.po.external_code
      // }
    },
    gr: function () {
      this.table.data = []
      this.table.data_charge = []
      // this.po_date = this.gr[0].po_date;
        this.table.project = this.gr[0].project;
        this.getListItemNt();
      // if(this.po_type == "1"){
      // } else {
      //   this.getListItem();
      // }
    },
    "table.data": {
      handler: function() {
        for (let i = 0; i < this.table.data.length; i++) {
          if (this.table.data[i].wh_tax_object != null) {
            this.table.data[i].wh_tax = ((this.table.data[i].wh_tax_object.Rate / 100) * parseFloat(this.table.data[i].net.replace(/,/g, ""))).toFixed(2)
              .toString()
              .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            this.table.data[i].wh_tax_rate = this.table.data[i].wh_tax_object.Rate
            this.table.data[i].wh_tax_id = this.table.data[i].wh_tax_object.ID
            this.$nextTick(() => {
              this.oldValue[i] = this.newValue[i]
            });
            this.newValue[i] = this.table.data[i].wh_tax_rate

            // console.log("old ", this.oldValue[i])
            // console.log("new ", this.newValue[i])

            if (this.oldValue[i] != this.newValue[i]) {
              this.cal()
            }
          }
        }
      },
      deep: true
    }
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}

.vs-con-table .vs-con-tbody {
  overflow-y: hidden !important;
}
</style>