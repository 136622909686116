<style>
.reportrange-text {
  min-height: 40px !important;
}
</style>
<template>
  <vx-card title="Supplier Invoice">
    <!-- Jika is_eh true, hanya tampilkan tab OCR -->
    <div v-show="is_eh" class="vx-row">
      <vs-tabs>
        <vs-tab label="OCR">
          <ocr></ocr>
        </vs-tab>
      </vs-tabs>
    </div>
    <!-- Jika is_eh false, tampilkan semua tab -->
    <div v-show="!is_eh" class="vx-row">
      <vs-tabs>
        <vs-tab label="OCR">
          <ocr></ocr>
        </vs-tab>
        <vs-tab label="Trade">
          <invoice-trade></invoice-trade>
        </vs-tab>
        <vs-tab label="Non Trade">
          <div class="tab-text">
            <non-trade></non-trade>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import InvoiceTrade from "./index-vendor-invoice.vue";
import NonTrade from "./../vendor_invoice_nt";
import Ocr from "./../vendor_invoice_processor_ocr";

export default {
  components: {
    InvoiceTrade,
    NonTrade,
    Ocr
  },
  data() {
    return {
      is_eh: false,
    };
  },
  methods: {
    checkUserEh() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/pi/check-eh-role")
        .then((resp) => {
          console.log("RESP",resp)
          if (resp.code == 200) {
            this.is_eh = resp.data.has_exception_handling_role;
            console.log("IS EH",this.is_eh)
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get cek eh role",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          // this.$vs.loading.close();
        });
    }
  },
  mounted() {
    this.checkUserEh();
  },
};
</script>